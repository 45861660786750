<template>
  <div>
    <div class="head_tbl">
      <div class="pos_left">
        <strong class="tit_text">결제정보</strong>
      </div>
    </div>
    <div class="tbl_comm tbl_view">
      <table>
        <colgroup>
          <col style="width: 171px" />
          <col />
          <col style="width: 171px" />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <th>증빙유형</th>
            <td colspan="3">
              {{ proofName }}
            </td>
          </tr>
          <tr>
            <th>증빙일자</th>
            <td>{{ certificateItemData.proofDate }}</td>
            <th>증빙번호</th>
            <!-- <td>{{  certificateItemData.payment.certificateId}}</td> -->
            <td>{{ certificateItemData.proofNum }}</td>
          </tr>
          <tr>
            <th>결제수단</th>
            <td>{{ paymentTypeName }}</td>
            <th>지급예정일</th>
            <td>{{ certificateItemData.payment.expenditureDate }}</td>
          </tr>
          <tr>
            <th>통화코드</th>
            <td>{{ certificateItemData.payment.currency }}</td>
            <th>지급예정일 수정사유</th>
            <td>{{ certificateItemData.payment.modReason }}</td>
          </tr>
          <tr>
            <th>공급가액</th>
            <td>{{ certificateItemData.payment.supplyPrice | currency }}</td>
            <th>세액</th>
            <td>{{ certificateItemData.payment.taxAmount | currency }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="tbl_foot">
      <div class="area_total">
        <dl>
          <dt>총금액<span class="txt_info">(공급가액+세액)</span></dt>
          <dd>
            {{ certificateItemData.payment.totalAmount | currency
            }}<span class="util_total">{{ certificateItemData.payment.currency }}</span>
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StatementPayment",
  props: {
    certificateItemData: Object,
  },
  data() {
    return {
      // 증빙유형
      proofTypeList: [
        { code: "AT", name: "세금계산서(자동)" },
        { code: "TB", name: "세금계산서(수동)" },
        { code: "PT", name: "세금계산서(종이)" },
        { code: "AB", name: "계산서(자동)" },
        { code: "BL", name: "계산서(수동)" },
        { code: "CP", name: "법인카드(개인)" },
        { code: "ID", name: "개인카드" },
        { code: "PB", name: "계산서(종이)" },
        { code: "RC", name: "영수증" },
        { code: "WT", name: "원천세" },
        { code: "NN", name: "증빙없음" },
      ],
      paymentTypeList: [
        { code: "AT", name: "계좌이체(원화)" },
        { code: "AD", name: "자동이체" },
        { code: "GR", name: "지로" },
        { code: "CD", name: "사내기부금" },
        { code: "DA", name: "자동이체(동이의결)" },
        { code: "NN", name: "증빙없음" },
      ],
      currencyDataList: [
        { code: "KRW", name: "KRW" },
        { code: "USD", name: "USD" },
        { code: "EUR", name: "EUR" },
        { code: "JPY", name: "JPY" },
        { code: "CNY", name: "CNY" },
        { code: "", name: "직접입력" },
      ],
    };
  },
  computed: {
    paymentTypeName() {
      if (this.certificateItemData.payment.paymentType == "") {
        return "";
      }
      const currency = this.certificateItemData.payment.currency || "원화";
      this.paymentTypeList.find((item) => item.code == "AT").name = "계좌이체(" + currency + ")";
      return this.paymentTypeList.find(
        (item) => item.code == this.certificateItemData.payment.paymentType,
      ).name;
    },
    proofName() {
      if (this.certificateItemData.proofName == "") {
        return "";
      }
      // return this.paymentTypeList.find(item => item.code == this.certificateItemData.payment.proofName).name;
      return this.proofTypeList.find((item) => item.code == this.certificateItemData.proofType)
        .name;
    },
  },
};
</script>
